// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./colors";

// Options
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

@import "./spacing";

@import "./layout";

@import "./borders";

@import "./typography";

@import "./tables";

@import "./buttons";

@import "./forms";

@import "./selects";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "./utilities/map-overrides";

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";

@import "./utilities/box-shadows";

// 6. Optionally include any other parts as needed
// Layout & components

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// Dirty hack to get the form-control class to work with the validation
// Need to find a better way to do this
@font-face {
  font-family: "Axiforma-Light";
  src: url("/fonts/Axiforma-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url("/fonts/Axiforma-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Axiforma-Bold";
  src: url("/fonts/Axiforma-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "BBManualMono-Regular";
  src: url("/fonts/BBManualMonoProTX-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "BBManualMono-Bold";
  src: url("/fonts/BBManualMonoProTX-Bold.woff2") format("woff2");
}

$blue: #00e6ff;
$green: #34f870;
$white: #ffffff;
$black: #000000;

html {
  background-color: $black;
}
a {
  text-decoration: none;
}

/* General scrollbar styling */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height for horizontal scrollbars */
}

/* Style the track (background) */
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* Style the thumb (the part that is draggable) */
::-webkit-scrollbar-thumb {
  background-color: #00e6ff;
  border-radius: 10px;
}

/* Hover effect for the thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #00e6ff;
}

.pagination {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: rotate(90deg);
  transform-origin: right;
  z-index: 1;
  .active > .page-link {
    background-color: $blue;
    border-color: $blue;
  }
  .page-link {
    border-radius: 50% !important;
    display: block;
    border: 2px solid $white;
    background: none;
    width: 12px;
    height: 12px;
    padding: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
}
.about {
  h1 {
    font-family: "Axiforma-Light", sans-serif;
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
  }
  h2 {
    font-family: "Axiforma-Light", sans-serif;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Axiforma-Bold", sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }

  p.video {
    font-family: "BBManualMono-Regular", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  p.lead {
    // font-family: "BBManualMono-Regular", sans-serif;
    // font-style: normal;
    // font-size: 21px;
    // line-height: 26px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: "BBManualMono-Regular", sans-serif;
  }
  p.small {
    font-family: "BBManualMono-Regular", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    &.left {
      text-align: left;
    }
  }
  p {
    // font-family: "BBManualMono-Regular", sans-serif;
    // font-style: normal;
    // font-size: 16px;
    // line-height: 24px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: "BBManualMono-Regular", sans-serif;
  }
  .video-panel {
    background: #212121;
    padding: 40px;
  }
  > * {
    text-align: center;
  }
}
.heading,
h1 {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 5px;
  font-family: "Axiforma-Light", sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: normal;
}

.section-heading,
h3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 5px;
  font-family: "Axiforma-Bold", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

.text-big,
h2 {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.5px;
  font-family: "BBManualMono-Regular", sans-serif;
  font-weight: normal;
}

.text-body,
p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-family: "BBManualMono-Regular", sans-serif;
}

.text-fine,
.text-fine p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-family: "BBManualMono-Regular", sans-serif;
}

.text-tiny {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-family: "BBManualMono-Regular", sans-serif;
}

.button,
button {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  font-family: "BBManualMono-Bold", sans-serif;
  padding: 9px 16px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  border: 2px solid currentColor;
  margin-left: 10px;
  text-decoration: none;
}

.button-tiny {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  font-family: "BBManualMono-Bold", sans-serif;
}

// style for a button with each state
.button {
  &.btn-high-emphasis {
    background: $blue;
    border-radius: 24px;
    color: $black;
    &:hover {
      background: linear-gradient(90deg, $green 0%, $blue 100%);
      border-radius: 24px;
    }
    &:focus {
      background: $blue;
      border: 2px solid $black;
      box-shadow: 0px 0px 0px 2px $blue;
      border-radius: 24px;
    }
    &:active {
      background: linear-gradient(90deg, $blue 0%, $green 100%);
      border-radius: 24px;
    }
    > svg {
      path {
        fill: $black;
      }
      rect {
        display: none;
      }
    }
  }
  &.btn-medium-emphasis {
    background: rgba(0, 0, 0, 0.4);
    border: 2px solid $blue;
    border-radius: 24px;
    color: $blue;
    &:hover {
      background: linear-gradient(90deg, $green 0%, $blue 100%);
      border-radius: 24px;
      color: $black;
      > svg {
        path {
          fill: $black;
        }
        rect {
          display: none;
        }
      }
    }
    &:focus {
      background: $blue;
      border: 2px solid $black;
      box-shadow: 0px 0px 0px 2px $blue;
      border-radius: 24px;
      color: $black;
      > svg {
        path {
          fill: $black;
        }
        rect {
          display: none;
        }
      }
    }
    &:active {
      background: linear-gradient(90deg, $blue 0%, $green 100%);
      border-radius: 24px;
      color: $black;
      > svg {
        path {
          fill: $black;
        }
        rect {
          display: none;
        }
      }
    }
    > svg {
      path {
        fill: $blue;
      }
      rect {
        display: none;
      }
    }
  }
  &.btn-low-emphasis {
    background: transparent;
    border: none;
    border-radius: 24px;
    color: $blue;
    border: 2px solid transparent;
    &:hover {
      background: linear-gradient(90deg, $green 0%, $blue 100%);
      border-radius: 24px;
      color: $black;
      > svg {
        path {
          fill: $black;
        }
        rect {
          display: none;
        }
      }
    }
    &:focus {
      border: 2px solid $blue;
      border-radius: 24px;
      > svg {
        rect {
          display: none;
        }
      }
    }
    &:active {
      background: linear-gradient(90deg, $blue 0%, $green 100%);
      border-radius: 24px;
      color: $black;
    }
  }
  &.btn-icon-emphasis {
    background: transparent;
    border: none;
  }
  &.scroll {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
      color: white;
    }
  }
}

.story-content {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  width: 265px;
  height: 400px;
  max-width: 50vh;
  transform: translate(-50%, -50%);
  text-align: left;
  &.left {
    left: 40%;
  }
  &.right {
    left: 60%;
  }
  .story-transcript-wrapper {
    position: relative;
    --mask: linear-gradient(to top, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
  .story-transcript {
    text-align: left;
    --mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
}

  .transcript-audio {
    border: 2px solid #ffffff;
    border-radius: 50px;
    padding: 0;
    text-align: left;
    width: auto;
    display: inline-block;
    padding: 0 10px 0 0;
    text-transform: uppercase;
    .button {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      transform: translate(-2px, 0px);
      rect {
        stroke: #ffffff;
      }
    }
  }

.home-mobile {
  .story-content {
    top: 47%;
    &.left {
      left: 50%;
    }
    &.right {
      left: 50%;
    }
  }
  .story-content {
    width: 250px;
    height: 50vh;
    max-width: 50vh;
    max-height: 50vh;
    // transform: translate(-28%, -50%);
    text-align: left;
  }
}

.brand-mobile {
  display: flex;
  justify-content: end;
  .button {
    background-color: transparent;
    padding: 0;
    span {
      display: none;
    }
  }
    .resources {
      border: 2px solid #00e6ff;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 9px;
      margin-top: 2px;
    }
}

.modal-header {
  border: none !important;
}
.modal-body {
  width: 80%;
  margin-left:10%;
}
.modal-content {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.btn-close {
  filter: brightness(0) invert(1);
}

.share-modal {
  .modal-content {
    border: 1px solid #4D4D4D;
    border-radius: 0;
    padding: 0 0 30px;
    h3 {
      font-size: 16px;
    }
  }
}